.navbar-logo {
  height: 40px;
}
.sticky-navbar {
  position: fixed;
  top: 0; /* Stick to the top */
  z-index: 500 !important; /* Ensure it's on top of other content */
  background-color: #fff; /* Adjust background if needed */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: add a shadow */
  width: 100vw;
  
}
.navbar-account-btn{
    background-color: white;

}
.logout-btn{
    display: flex;
    /* justify-content: space-between; */
}
.navbar-account-btn{
    font-size: 22px !important;
}
.navbar-account-btn:hover{
    cursor: pointer;
}
.searchbar input{
    height: 35px;
    border: 1px solid rgb(81, 81, 81) !important;
}
.searchbar button{
    height: 35px !important;
}
.headline {
    text-align: center;
    padding: 2px 0px 2px 0px;
    background-color: black;
    color: white;
    font-size: 8px !important;
    letter-spacing: 2px;
}

.headline a {
    font-size: 8px !important;
    letter-spacing: 2px;
    text-decoration: none;
    color: white !important;
}

/* Ensures that visited links also have the same style */
.headline a:visited {
    color: white;
}

/* Ensures that active (clicked) links also retain the style */
.headline a:active {
    color: white;
}

/* Optional: You can add hover state if needed */
.headline a:hover {
    text-decoration: underline;
    color: white;
}

.navbar-logo:hover{
    cursor: pointer;
}
.offcanvas-body, .offcanvas-header{
    background: linear-gradient(90deg, #ffffff, #cacaca) !important;
}
.offcanvas-header{
}
.navbar-search {
  border-radius: 30px !important;
}
.ant-input-search-button {
  background-color: var(--accent-color) !important;
}
.dropdown-item:active {
  background-color: #eed7ff !important;
  color: #24003e !important;
}
.faculties-btn-navbar {
  color: var(--accent-color) !important;
  margin-right: 20px;
  background-color: transparent;
  border: none;
}
.ant-popover-inner {
  width: 300px !important;
}
.offcanvas-data {
  background-color: transparent !important;
  border: none !important;
  color: black !important;
  text-align: left !important ;
}
.offcanvas-data:hover {
  background-color: rgb(242, 242, 242) !important;
  font-weight: 500;
}
.faculties-btn-navbar i {
  font-size: 10px;
  margin-left: 5px;
}
.search-result-item {
  padding: 5px 0;
  display: flex;
  align-items: center;
}
.nav-link {
  color: var(--accent-color) !important;
  margin-right: 20px;
}
.search-popover {
  /* max-height: 200px;  */
  overflow-y: auto; /* Enable scrolling */
}
.navbar-toggler {
  border-color: var(--accent-color) !important;
  box-shadow: none !important;
}

.navbar-toggler::after {
  border-color: var(--accent-color) !important;
}


