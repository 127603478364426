.text{
    color: var(--accent-color);
    font-weight: 200;
}
.text strong{
    color: var(--accent-color);
    font-weight: 700;
}
.heading{
    color: var(--accent-color);
    font-weight: 600;
    font-size: 32px;
    margin-top: 20px;
}


@media screen and (max-width:550px){
    .text{
        font-size: 12px;
    }
    .heading{
        font-size: 24px;
    }
}