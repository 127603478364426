/* Footer.css */

h1, h2, h3, h4, h5, h6 {}
a, a:hover, a:focus, a:active {
    text-decoration: none;
    outline: none;
}

a, a:active, a:focus {
    color: #333;
    text-decoration: none;
    transition-timing-function: ease-in-out;
    transition-duration: .2s;
}
.footer-headline{
    color: black;
    padding-top: 25px !important;

}
ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

img {
    max-width: 100%;
    height: auto;
}

section {
    padding: 60px 0;
}

.footer {
    background: linear-gradient(105deg, #ffffff, #e8e8e8);
    padding-top: 40px;
    padding-bottom: 20px;
}

.single_footer h4 {
    color: var(--accent-color);
    margin-top: 0;
    margin-bottom: 25px;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 20px;
}

.single_footer h4::after {
    content: "";
    display: block;
    height: 2px;
    width: 40px;
    background: var(--accent-color);
    margin-top: 20px;
}

.single_footer p {
    color: var(--accent-color);
}

.single_footer ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.single_footer ul li a {
    color: var(--accent-color);
    /* transition: all 0.3s ease 0s; */
    line-height: 36px;
    font-size: 15px;
    text-transform: capitalize;
}

.single_footer ul li a:hover {
    cursor: pointer;
    font-weight: 500;
}

.single_footer_address ul li {
    color: var(--accent-color);
}

.single_footer_address ul li span {
    font-weight: 400;
    color: var(--accent-color);
    line-height: 28px;
}

.contact_social ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}

.subscribe {
    display: block;
    position: relative;
    margin-top: 15px;
    width: 100%;
}

.subscribe__input {
    background-color: var(--accent-color);
    border: medium none;
    border-radius: 5px;
    color: #333;
    display: block;
    font-size: 15px;
    font-weight: 500;
    height: 60px;
    letter-spacing: 0.4px;
    margin: 0;
    padding: 0 150px 0 20px;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
}

@media only screen and (max-width:768px) {
    .subscribe__input {
        padding: 0 50px 0 20px;
    }
}

.subscribe__btn {
    background-color: transparent;
    border-radius: 0 25px 25px 0;
    color: var(--accent-color);
    cursor: pointer;
    display: block;
    font-size: 20px;
    height: 60px;
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
}

.subscribe__btn i {
    transition: all 0.3s ease 0s;
}

@media only screen and (max-width:768px) {
    .subscribe__btn {
        right: 0px;
    }
}

.subscribe__btn:hover i {
    color: var(--accent-color);
}

button {
    padding: 0;
    border: none;
    background-color: transparent;
}

.social_profile {
    margin-top: 40px;
}

.social_profile ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}

.social_profile ul li {
    float: left;
}

.social_profile ul li a {
    text-align: center;
    border: 0px;
    text-transform: uppercase;
    transition: all 0.3s ease 0s;
    margin: 0px 5px;
    font-size: 18px;
    color: var(--accent-color);
    border-radius: 30px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    display: block;
    border: 1px solid rgba(255, 255, 255, 0.2);
}
.footer-logo{
    width: 80%;
}
@media only screen and (max-width:768px) {
    .social_profile ul li a {
        margin-right: 10px;
        margin-bottom: 10px;
    }
}
@media only screen and (max-width:992px) {
    
    .footer-logo{
        width: 60%;
        margin-bottom: 20px;
    }
    .single_footer h4 {
        margin-top: 20px;
    }
}

@media only screen and (max-width:480px) {
    .social_profile ul li a {
        width: 40px;
        height: 40px;
        line-height: 40px;
    }
}

.social_profile ul li a:hover {
    background: var(--accent-color);
    border: 1px solid var(--accent-color);
    color: var(--accent-color);
    border: 0px;
}

.copyright {
    margin-top: 10px;
    padding-top: 20px;
    color: var(--accent-color);
    font-size: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    text-align: center;
}

.copyright a {
    color: var(--accent-color);
    transition: all 0.2s ease 0s;
}

.copyright a:hover {
    color: var(--accent-color);
}
