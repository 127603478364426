.faculty-card {
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
}
.faculty-card {
  color:  var(--accent-color) !important;
}
.faculty-image {
  width: 90%;
  height: 300px;
  object-fit: cover;
}

.faculty-rating {
  margin-top: 10px;
}

.review-count {
  margin-left: 8px;
  color: #888;
}
.faculty-card-name {
  font-size: 16px;
}
.department-card-btn:hover {
  color:  var(--accent-color) !important;
  border-color:  var(--accent-color) !important;
}

/* From Uiverse.io by cssbuttons-io */ /* From Uiverse.io by kamehame-ha */
.faculty-card-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  gap: 15px;
  background-color: #000000;
  outline: 2px #181717 solid;
  
  outline-offset: -3px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: 400ms;
}

.faculty-card-button .text {
  color: white ;
  font-weight: 700;
  font-size: 1em;
  transition: 400ms;
}
.faculty-card-button:hover{
    background-color: white !important;
}
.faculty-card-button:hover p{
    color: black;
}
.faculty-card-button svg path {
  transition: 400ms;
}



.card-height{
    height: 170px !important;
}
.product-card-rate .ant-rate-star-full {
    font-size: 15px;
  }

  .product-card-rate .ant-rate-star-half {
    font-size: 15px;
  }

  .product-card-rate .ant-rate-star-zero {
    font-size: 15px;
  }

@media screen and (min-width: 640px) {
    .card-height{
        height: 190px !important;
    }
}
@media screen and (max-width: 640px) {
  .ant-card-meta-title {
    font-size: 16px !important;
  }
  .ant-card-meta-detail {
    font-size: 15px !important;
  }
  .ant-rate {
    font-size: 16px !important;
  }
  .review-count {
    font-size: 12px !important;
  }
  .faculty-image {
    height: 250px;
  }
  .product-card-rate .ant-rate-star-full {
    font-size: 15px;
  }

  .product-card-rate .ant-rate-star-half {
    font-size: 15px;
  }

  .product-card-rate .ant-rate-star-zero {
    font-size: 15px;
  }
  .button .text {
    color: white;
    font-weight: 700;
    font-size: .7em;
    transition: 400ms;
  }
}

@media screen and (max-width: 550px) {
    .ant-card-meta-title {
        font-size: 14px !important;
      }
      .ant-card-meta-detail {
        font-size: 13px !important;
      }
}
@media screen and (min-width:768px) and (max-width:850px){
    
  .ant-card-meta-title {
    font-size: 16px !important;
  }
  .ant-card-meta-detail {
    font-size: 15px !important;
  }
}