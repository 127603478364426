body {
  margin: 0;
  /* font-family: "Poppins", sans-serif !important;*/
  
  font-family: "Montserrat", sans-serif; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
}

:root {
    --accent-color: #050505; /* Define your accent color */
    --accent-hover-color: #000000; /* Define your accent hover color */
  }
  

  /*----------------------------------------------------------------------------webkit-scrollbar*/
  ::-webkit-scrollbar {
    width: 7px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px var(--accent-color);
    border-radius: 5px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--accent-color);
    border-radius: 5px;
    box-shadow: inset 0 0 5px var(--accent-color);
    width: 1px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--accent-color);
  }
  