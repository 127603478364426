/* .main-image {
  background: url("https://res.cloudinary.com/dmlxb4ea9/image/upload/v1726561915/Untitled-1_jhidos.png");
  height: 600px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  z-index: 1;
  position: relative;
} */
.faculty-image {
  width: 100% !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.department-card {
  border-radius: 10px;
  border: 1px solid rgb(229, 229, 229);
}
.parallax-component {
  position: relative;
  height: 500px; /* Default height */
  background-size: cover;
  background-position: center;
}

.home-main-img {
  position: relative;
  z-index: 1;
}
.parallax-2-text {
  background: linear-gradient(90deg, #ffffff, #d8d8d8) !important;
  padding: 25px;
  border-radius: 10px;
  color: black;
}
.acknowledge-btn{
    color: white !important;
    background-color: black !important;
    padding: 15px 30px;
}
.department-card-name {
  font-size: 20px;
  font-weight: 500;
  margin-top: 10px;
  /* color: blueviolet !important; */
}
.department-card-btn {
  padding: 20px 20px;
  width: 100%;
  background-color: white;
  border: 1px solid rgb(229, 229, 229);
}
@media screen and (max-width: 550px) {
  .parallax_2_text p {
    font-size: 14px;
  }
  .parallax_2_text h2 {
    font-size: 20px;
  }
}
.department-card-btn:hover {
  background-color: rgb(242, 242, 242);
}
.parallax-component {
  height: 500px; /* Default height */
}
.home-main-img {
  text-align: start;
  margin-left: 30px;
}
.home-main-img h2 {
  color: white;
  font-size: 50px;
  margin-bottom: 20px;
  text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.355); /* Horizontal offset, vertical offset, blur radius, color */
}
.department-card-name {
  margin: 0px;
}
.slick-dots {
  /* padding-top: 15px !important; */
}
.slick-next {
  color: black !important;
}
.slick-prev {
  color: black !important;
}
.slick-dots li button {
  background-color: black !important;
  border-color: black !important;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .parallax-component {
    height: 600px; /* Height for smaller screens */
  }
}
@media (max-width: 550px) {
  .faculty-image {
    height: 200px !important;
  }
  .dropdown-item {
    font-size: 12px !important;
  }
}
@media screen and (min-width: 0px) {
  .main-image {
    height: 300px;
  }
  .department-card-name {
    font-size: 16px;
  }
}

@media screen and (min-width: 680px) {
  .main-image {
    height: 370px;
  }
  .department-card-name {
    font-size: 16px;
  }
}
@media screen and (max-width: 680px) {
  .ant-card-body {
    padding: 10px !important;
  }
}

@media screen and (min-width: 900px) {
  .main-image {
    height: 500px;
  }
}

@media screen and (min-width: 1100px) {
  .main-image {
    height: 600px;
  }
}

/* From Uiverse.io by cssbuttons-io */ /* From Uiverse.io by kamehame-ha */
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  gap: 15px;
  background-color: #181717;
  outline: 2px #181717 solid;
  outline-offset: -3px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: 400ms;
}

.button .text {
  color: white;
  font-weight: 700;
  font-size: 1em;
  transition: 400ms;
}

.button svg path {
  transition: 400ms;
}

.button:hover {
  background-color: transparent;
}

.button:hover .text {
  color: #181717;
}

.button:hover svg path {
  fill: #181717;
}
