/* Signin Form Styling */
.form-title {
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: 700;
  text-align: center;
}

.password-helper {
  display: block;
  margin: 10px 0;
}

.submit-btn {
  width: 100%;
}

.signin-form {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  margin: 60px 40px !important;
  padding: 40px;
  width: 70%;
  border-radius: 15px;
}

/* Container Flex Alignment */
.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo-container img {
  width: 70%;
}

.logo-container {
  text-align: center;
  align-self: center;
}

/* Responsive Styling */
@media screen and (max-width: 1150px) {
  .signin-form {
    width: 90%;
  }

  .logo-container img {
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .logo-container {
    display: none;
  }
  .signin-form {
    margin: 60px 0px !important;
  }
}

/* Input Field Styling */
.input-field {
  height: 40px;
  margin-top: 6px;
}

.input-field .ant-input-prefix {
  margin-right: 15px;
}

.form-item label {
  margin-top: 14px !important;
  font-weight: 600 !important;
}

/* Forgot Password Styling */
.password-helper {
  display: flex;
  justify-content: end;
  margin: 0px;
}

.forgot-password-btn {
  margin: 0px;
  color: black;
  font-weight: 700;
}

.submit-btn {
  height: 40px;
  background-color: black;
  border: none;
  margin-top: 20px;
  font-weight: 600;
}

.signup-toggle {
  color: black;
  font-weight: 700;
}
.signup-toggle:hover {
  cursor: pointer;
}
.dont-have-account {
  color: rgb(88, 88, 88);
}
.toggle-bar {
  text-align: center;
  margin-top: 10px;
}
.google-btn div{
background-color: white !important;
color: black !important;
}
.google-btn{
    display: flex;
    justify-content: center;

}
.google-btn div{
    background-color:  transparent !important;
}
.google-btn div[role="button"]{
    width: 100% !important;
    border: 1px solid rgba(0, 0, 0, 0.151) !important;
    box-shadow: none !important;
}
.google-btn div[role="button"]:hover{
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.053) !important;
}